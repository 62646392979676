import React from 'react';
import { Pie } from 'react-chartjs-2';
import 'chartjs-plugin-datalabels';

import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  Title,
  PieController
} from 'chart.js';

ChartJS.register(
  ArcElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  Title,
  PieController
);

// Function to generate random colors
const generateRandomColor = () => {
  let maxVal = 0xFFFFFF; // 16777215
  let randomNumber = Math.random() * maxVal; 
  randomNumber = Math.floor(randomNumber);
  randomNumber = randomNumber.toString(16);
  let randColor = randomNumber.padStart(6, 0);   
  return `#${randColor.toUpperCase()}`
}

// Your country data
const countryData = {
  "Nigeria": 16,
  "United States": 4,
  "United Kingdom": 5, // Combined count for UK and United Kingdom
  "South Africa": 1,
  "Kenya": 1,
  "Algeria": 1,
  "Germany": 1,
  "Ethiopia": 1
};

// Generate a unique color for each country
const chartColors = Object.keys(countryData).map(() => generateRandomColor());

const AnimatedPieChart = () => {
  const data = {
    labels: Object.keys(countryData),
    datasets: [
      {
        data: Object.values(countryData),
        backgroundColor: chartColors,
        hoverBackgroundColor: chartColors,
      },
    ],
  };

  const options = {
    responsive: true,
    animation: {
      duration: 2000, // Duration in milliseconds
      easing: 'easeOutBounce', // Animation easing function
      onProgress: (animation) => {
        console.log(`Progress: ${animation.currentStep / animation.numSteps}`);
      },
      onComplete: () => {
        console.log('Animation completed');
      }
    },
    plugins: {
      legend: {
        position: 'bottom',
      },
      tooltip: {
        callbacks: {
          label: (context) => {
            const label = context.label || '';
            const value = context.parsed;
            const total = context.dataset.data.reduce((acc, val) => acc + val, 0);
            const percentage = ((value / total) * 100).toFixed(2) + '%';
            return label + ': ' + percentage;
          }
        }
      },
      datalabels: {
        formatter: (value, context) => {
          const total = context.chart.data.datasets[0].data.reduce((acc, val) => acc + val, 0);
          const percentage = ((value / total) * 100).toFixed(2) + '%';
          return percentage;
        },
        color: '#fff',
      }
    },
  };

  return <Pie data={data} options={options} />;
};

export default AnimatedPieChart;
