// Navigation.js

import React from 'react';
import { Link } from 'react-router-dom';
import { Navbar, Nav, NavDropdown } from 'react-bootstrap';
import './navigation.css'

function Navigation() {
    return (
        <Navbar bg="light" expand="lg">
            <Navbar.Brand as={Link} to="/">ARN</Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="mr-auto">
                    <Nav.Link as={Link} to="/HomePage">Home</Nav.Link>

                    <NavDropdown title="Management" id="management-dropdown">
                        <NavDropdown.Item as={Link} to="/Trustees">Trustees</NavDropdown.Item>
                        <NavDropdown.Item as={Link} to="/Directors">Directors</NavDropdown.Item>
                        <NavDropdown.Item as={Link} to="/Associates">Associates</NavDropdown.Item>
                        <NavDropdown.Item as={Link} to="/Consultants">Consultants</NavDropdown.Item>
                        <NavDropdown.Item as={Link} to="/UserProfile">Members</NavDropdown.Item>
                        <NavDropdown.Item as={Link} to="/Researchers">Researchers</NavDropdown.Item>
                    </NavDropdown>

                    <NavDropdown title="Activities" id="activities-dropdown">
                        <NavDropdown.Item as={Link} to="/Webinar">Webinars</NavDropdown.Item>
                        <NavDropdown.Item as={Link} to="/Workshops">Workshops</NavDropdown.Item>
                        <NavDropdown.Item as={Link} to="/Conferences">Conferences</NavDropdown.Item>
                        <NavDropdown.Item as={Link} to="/Socials">Socials</NavDropdown.Item>
                    </NavDropdown>

                    <NavDropdown title="Research" id="research-dropdown">
                        <NavDropdown.Item as={Link} to="/ResearchClusters">Research Clusters</NavDropdown.Item>
                        <NavDropdown.Item as={Link} to="/DataAnalytics">Data Analytics</NavDropdown.Item>
                        <NavDropdown.Item as={Link} to="/AfricanStudies">African Studies</NavDropdown.Item>
                    </NavDropdown>

                    <NavDropdown title="Opportunities" id="opportunities-dropdown">
                        <NavDropdown.Item as={Link} to="/Internship">Internship</NavDropdown.Item>
                        <NavDropdown.Item as={Link} to="/Scholarships">Scholarships</NavDropdown.Item>
                        <NavDropdown.Item as={Link} to="/Grants">Grants</NavDropdown.Item>
                        <NavDropdown.Item as={Link} to="/Jobs">Jobs</NavDropdown.Item>
                    </NavDropdown>

                    <NavDropdown title="Projects" id="projects-dropdown">
                        <NavDropdown.Item as={Link} to="/Publications">Publications</NavDropdown.Item>
                        <NavDropdown.Item as={Link} to="/DigitalArchives">Digital Archives</NavDropdown.Item>
                    </NavDropdown>

                    <NavDropdown title="Resources" id="resources-dropdown">
                        <NavDropdown.Item as={Link} to="/E-Learning">E-Learning</NavDropdown.Item>
                        <NavDropdown.Item as={Link} to="/Library">Library</NavDropdown.Item>
                        <NavDropdown.Item as={Link} to="/Projects">Projects</NavDropdown.Item>
                        <NavDropdown.Item as={Link} to="/Studio">Studio</NavDropdown.Item>
                        <NavDropdown.Item as={Link} to="/Members_Blogs">Members' Blogs</NavDropdown.Item>
                    </NavDropdown>

                    <Nav.Link as={Link} to="/News">News</Nav.Link>
                    <Nav.Link as={Link} to="/Donate">Donate</Nav.Link>
                    <Nav.Link as={Link} to="/Contact">Contact</Nav.Link>
                </Nav>
            </Navbar.Collapse>
        </Navbar>
    );
}

export default Navigation;
