import React from "react";
import { useTranslation } from 'react-i18next';

const LanguageSwitcher = () => {
  const { i18n } = useTranslation(); // Directly use i18n from useTranslation hook

  const languages = [
    { code: "ar", name: "العربية" },
    { code: "en", name: "English" },
    { code: "ha", name: "Hausa" },
    { code: "ig", name: "Igbo" },
    { code: "pi", name: "Pigin" },
    { code: "sh", name: "Shona" },
    { code: "so", name: "Somali" },
    { code: "sw", name: "Kiswahili" },
    { code: "xh", name: "Xhosa" },
    { code: "yo", name: "Yoruba" },
    { code: "zu", name: "Zulu" },
    { code: "af", name: "Afrikaans" }
  ];

  const handleChange = (event) => {
    const languageCode = event.target.value;
    i18n.changeLanguage(languageCode); // Use i18n to change language
  };

  return (
    <div className="language-switcher">
      <select onChange={handleChange}>
        {languages.map(({ code, name }) => (
          <option key={code} value={code}>
            {name}
          </option>
        ))}
      </select>
    </div>
  );
};

export default LanguageSwitcher;
