import React, { useState, useEffect } from 'react';
import axios from 'axios';
import webinarsData from './pastWebinar.json';
import './Webinar.css';

function Webinar() {
  const [webinars, setWebinars] = useState([]);
  const [currentWebinarIndex, setCurrentWebinarIndex] = useState(0);
  const [currentWebinarTitle, setCurrentWebinarTitle] = useState('');
  const [youtubeVideos, setYoutubeVideos] = useState([]);

  useEffect(() => {
    setWebinars(webinarsData);
    if (webinars.length > 0) {
      setCurrentWebinarTitle(webinars[0].Title); // Default to the first webinar's title
    }
  }, [webinars]);

  useEffect(() => {
    if (currentWebinarTitle) {
      fetchYouTubeVideos();
    }
  }, [currentWebinarTitle]);

const fetchYouTubeVideos = () => {
const options = {
      method: "GET",
      url: "https://www.googleapis.com/youtube/v3/search",
      params: {
        part: "snippet",
        channelId: "UCNXeSmPsfsPsZcs6vkLIILg",
        maxResults: 30,
        q: currentWebinarTitle, // Query parameter for filtering
        key: "AIzaSyBoZvwniO6qzQVvBJy0yEaETvg60qXGUt8"
      }
    };


  axios.request(options).then(response => {
    const videos = response.data.items.map(item => ({
      ...item,
      videoLink: `https://www.youtube.com/embed/${item.id.videoId}`,
    }));
    setYoutubeVideos(videos);
  }).catch(error => console.error('Error fetching YouTube data:', error));
};

  const handlePrevClick = () => {
    setCurrentWebinarIndex(prevIndex => {
      const newIndex = prevIndex > 0 ? prevIndex - 1 : prevIndex;
      setCurrentWebinarTitle(webinars[newIndex].Title);
      return newIndex;
    });
  };

  const handleNextClick = () => {
    setCurrentWebinarIndex(prevIndex => {
      const newIndex = prevIndex < webinars.length - 1 ? prevIndex + 1 : prevIndex;
      setCurrentWebinarTitle(webinars[newIndex].Title);
      return newIndex;
    });
  };


  return (
  <div className="webinar-container">
    <h1 className="title">ARN Webinars</h1>
    <div className="content-container">
      {webinars.length > 0 && (
        <div className='webinarcard-container'>
        <div className="webinar-card">
          <h2>{webinars[currentWebinarIndex].Title}</h2>
          <p>{webinars[currentWebinarIndex]['Date and Time']}</p>
          
          <p>{webinars[currentWebinarIndex].Description}</p>
          <ul>
            {webinars[currentWebinarIndex].Speakers.map((speaker, index) => (
              <li key={index}>{speaker.Name} - {speaker.Topic}</li>
            ))}
          </ul>
          <div className="navigation">
            <button onClick={handlePrevClick}>Prev</button>
            <button onClick={handleNextClick}>Next</button>
          </div>
        </div>
        <div>
            <img src={`./images/${webinars[currentWebinarIndex].No}.jpg`} alt="Webinar" />
        </div>
        </div>
      )}

      <div className="youtube-section">
        <h2>YouTube Videos</h2>
        <div className="video-grid">
        {youtubeVideos.map((video, index) => (
            <a key={index} href={`https://www.youtube.com/watch?v=${video.id.videoId}`} target="_blank" rel="noopener noreferrer">
            <iframe
                src={video.videoLink}
                title={video.snippet.title}
                allowFullScreen>
            </iframe>
            </a>
        ))}
        </div>

      </div>
    </div>
  </div>
);
}

export default Webinar;


    






