import React, { useState, useEffect } from "react";
import jsonData from "../../object_formatted_data.json";
import "../../App.css"; // Make sure you import the CSS file.

function UserProfile() {
  const [profiles, setProfiles] = useState([]);

  useEffect(() => {
    setProfiles(jsonData);
  }, []);

  const placeholderImage = "placeholder_image.jpg";

  return (
    <div className="container mt-5">
      <h1 className="mb-4 text-center">User Profiles</h1>
      <div className="row">
        {profiles && Object.keys(profiles).length > 0 ? (
          Object.entries(profiles).map(([index, profile]) => (
            <div key={index} className="col-lg-4 col-md-6 mb-4">
              <div className="card h-100 card-hover-effect">
                <div className="row g-0">
                  <div className="col-md-4">
                    <img
                      src={placeholderImage}
                      className="img-fluid rounded-start"
                    />
                  </div>
                  <div className="col-md-8">
                    <div className="card-body">
                      <h5 className="card-title">{profile.Name}</h5>
                      <p className="card-text">{profile.Email}</p>
                      <p className="card-text">
                        {profile["Area of Specialization"]}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))
        ) : (
          <div className="col-12">
            <p className="text-center">Loading profiles...</p>
          </div>
        )}
      </div>
    </div>
  );
}

export default UserProfile;
