import React from 'react';
import { useTranslation } from 'react-i18next';
import { Container, Row, Card, Col, ShowModal, Modal } from 'react-bootstrap';
import LanguageSwitcher from './LanguageSwitcher';
import {useState} from 'react';
import './HomePage.css';
import AfricanPeopleImage from './img/african_people.png';
import AnimatedPieChart from './AnimatedPieChart'

function HomePage() {
  const [showLanguageSwitcher, setShowLanguageSwitcher] = useState(false);
  const { t, i18n } = useTranslation();

  const changeLanguage = (languageCode) => {
    i18n.changeLanguage(languageCode);
    setShowLanguageSwitcher(false);
  };

  return (
    <>
<Container className="mt-5">

{/* Section one*/}
<Container className="bg-primary text-white text-center p-5">
<h3 className="text-center">{t('welcome_message')}</h3>
</Container>
<div className="bg-white text-gray-800">
      <div className="container mx-auto px-4">
        <h1 className="text-3xl font-bold text-center my-8">Welcome to African Researchers Network CIC</h1>
        
        <div className="bg-blue-200 rounded-lg overflow-hidden">
          <img src={AfricanPeopleImage} alt="Map of Africa" className="w-full object-cover h-96" />
        </div>

        <div className="flex justify-center space-x-4 my-6">
          <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">Join us Today</button>
          <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">Subscribe to News</button>
          <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">Network on Slack</button>
          <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">Request our Service</button>
        </div>

        <div className="bg-blue-200 rounded-lg overflow-hidden my-8">
          <iframe width="100%" height="500" src="https://www.youtube.com/embed/wIGUQLVMTm4" title="Canva for Academic Researchers" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
        </div>
      </div>
    </div>
         {showLanguageSwitcher && (
        <LanguageSwitcher changeLanguage={changeLanguage} />
      )}
    <button 
        onClick={() => setShowLanguageSwitcher(!showLanguageSwitcher)}
        className="language-switcher-btn"
    >
        Change Language
    </button>
    <Row className="mt-5">
<Col md={4}>
<Card>
<Card.Header> Webinars and Workshops</Card.Header>
<Card.Body>
<Card.Text>{t('promoting_collaboration')}</Card.Text>
</Card.Body>
</Card>
</Col>
<Col md={4}>
<Card>
<Card.Header> Academic Publications</Card.Header>
<Card.Body>
<Card.Text>{t('documenting_knowledge')}</Card.Text>
</Card.Body>
</Card>
</Col>
<Col md={4}>
<Card>
<Card.Header> Digital Resources</Card.Header>
<Card.Body>
<Card.Text>{t('creating_online_repositories')}</Card.Text>
</Card.Body>
</Card>
</Col>
</Row>



        {/* Second Section */}
<div className="container mx-auto px-4 py-8">
    <h1 className="text-3xl font-bold text-center text-gray-800 mb-10">ARN Global Impact</h1>
    <div className="grid grid-cols-1 md:grid-cols-4 gap-4 mb-10">
        {/* First row of cards */}
        <div className="card bg-white p-6 rounded-lg shadow-md">
            <h2 className="text-xl font-semibold mb-4">Membership Statistics</h2>
            <AnimatedPieChart />
            <p className="text-gray-600">Our Members are spread across the Globe.</p>
        </div>
        <div className="card bg-white p-6 rounded-lg shadow-md">
            <h2 className="text-xl font-semibold mb-4">Academic Conversations</h2>
            <img src="https://source.unsplash.com/random/400x200?global" alt="Map" className="rounded-lg mb-4" />
            <p className="text-gray-600">A visual representation of our global subscriber base.</p>
        </div>
        <div className="card bg-white p-6 rounded-lg shadow-md">
            <h2 className="text-xl font-semibold mb-4">Infographics on Subscribers</h2>
            <img src="https://source.unsplash.com/random/400x200?global" alt="Map" className="rounded-lg mb-4" />
            <p className="text-gray-600">A visual representation of our global subscriber base.</p>
        </div>
        <div className="card bg-white p-6 rounded-lg shadow-md">
            <h2 className="text-xl font-semibold mb-4">Infographics on Subscribers</h2>
            <img src="https://source.unsplash.com/random/400x200?global" alt="Map" className="rounded-lg mb-4" />
            <p className="text-gray-600">A visual representation of our global subscriber base.</p>
        </div>

        {/* Second row of cards */}
        <div className="card bg-white p-6 rounded-lg shadow-md">
            <h2 className="text-xl font-semibold mb-4">Infographics on Subscribers</h2>
            <img src="https://source.unsplash.com/random/400x200?global" alt="Map" className="rounded-lg mb-4" />
            <p className="text-gray-600">A visual representation of our global subscriber base.</p>
        </div>
        <div className="card bg-white p-6 rounded-lg shadow-md">
            <h2 className="text-xl font-semibold mb-4">Infographics on Subscribers</h2>
            <img src="https://source.unsplash.com/random/400x200?global" alt="Map" className="rounded-lg mb-4" />
            <p className="text-gray-600">A visual representation of our global subscriber base.</p>
        </div>
        <div className="card bg-white p-6 rounded-lg shadow-md">
            <h2 className="text-xl font-semibold mb-4">Infographics on Subscribers</h2>
            <img src="https://source.unsplash.com/random/400x200?global" alt="Map" className="rounded-lg mb-4" />
            <p className="text-gray-600">A visual representation of our global subscriber base.</p>
        </div>
        <div className="card bg-white p-6 rounded-lg shadow-md">
            <h2 className="text-xl font-semibold mb-4">Infographics on Subscribers</h2>
            <img src="https://source.unsplash.com/random/400x200?global" alt="Map" className="rounded-lg mb-4" />
            <p className="text-gray-600">A visual representation of our global subscriber base.</p>
        </div>
    </div>

    <h2 className="text-3xl font-bold text-center text-gray-800 mb-10">ARN Meets Your Needs</h2>
    <div className="grid grid-cols-1 md:grid-cols-4 gap-4">
        {/* Additional cards or content as needed */}
    </div>
</div>

{/* Section Three */}
 {/* Third Section */}
  <div className="container mx-auto px-4 py-8">
      <h1 className="text-3xl font-bold text-center mb-8">Academic Webinars</h1>

      <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mb-8">
          {/* Repeated card structure for each category */}
          <div className="bg-white rounded-lg p-4 shadow-md card">
              <h2 className="text-xl font-semibold mb-2">Arts</h2>
              <p>Explore the world of arts through our curated webinars.</p>
          </div>
          {/* ... Other categories similar to above */}
      </div>

      <h2 className="text-2xl font-bold text-center mb-8">Digital Workshops</h2>
      <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mb-8">
          {/* Repeated card structure for each workshop */}
          <div className="bg-white rounded-lg p-4 shadow-md card">
              <h3 className="text-lg font-semibold mb-2">Using Research Tool</h3>
              <p>Enhance your research skills with our specialized tools.</p>
          </div>
          {/* ... Other workshops similar to above */}
      </div>

      <h2 className="text-2xl font-bold text-center mb-8">Past Guest Speakers</h2>
      <div className="grid grid-cols-1 md:grid-cols-4 gap-4 mb-8">
          {/* Repeated structure for each speaker */}
          <div className="bg-white rounded-lg p-4 shadow-md card">
              <img src="https://unsplash.it/200/200" alt="Speaker" className="rounded-lg mb-2" />
              <h4 className="text-md font-semibold">John Doe</h4>
              <p>Expert in Environmental Science</p>
          </div>
          {/* ... Other speakers similar to above */}
      </div>

      <h2 className="text-2xl font-bold text-center mb-8">Past Events Gallery</h2>
      <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
          {/* Repeated structure for each event */}
          <div className="bg-white rounded-lg p-4 shadow-md card">
              <img src="https://unsplash.it/400/300" alt="Event Photo" className="rounded-lg mb-2" />
              <h5 className="text-md font-semibold">Art & Design Workshop</h5>
              <p>A hands-on workshop for creative minds.</p>
          </div>
          {/* ... Other events similar to above */}
      </div>
  </div>


{/* <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Language Switcher</Modal.Title>
</Modal.Header>
<Modal.Body>
<LanguageSwitcher changeLanguage={changeLanguage} />
</Modal.Body>
</Modal> */}
</Container>
</>
);
}

export default HomePage;
