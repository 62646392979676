
import React from 'react';

function AboutUs() {
    return (
        <div className="about-us-container">
            <h2>About Us</h2>
            <img src="placeholder-image-about.jpg" alt="About ARN" className="about-us-image"/>
            <p>
                ARN (African Research Network) is an initiative dedicated to empowering African researchers and scholars by providing them with the resources, networks, and platforms they need to excel in their respective fields. Established in [Year], our mission is to foster a vibrant community of researchers across the continent who can contribute significantly to global knowledge and local development.
            </p>
            <p>
                Our vision is a world where African researchers are leading contributors to global scientific and academic discourse, addressing challenges and pushing the frontiers of knowledge.
            </p>
            <h3>Our History</h3>
            <p>
                ARN was founded by a group of African scientists, academics, and enthusiasts who recognized the untapped potential within the continent's research community. Since then, we've grown into a thriving network that supports hundreds of researchers through funding, mentorship, and collaboration opportunities.
            </p>
            <video controls className="about-us-video">
                <source src="placeholder-video-about.mp4" type="video/mp4"/>
                Your browser does not support the video tag.
            </video>
            <h3>Our Achievements</h3>
            <ul>
                <li>Facilitated over 100 research projects across Africa.</li>
                <li>Established partnerships with international research institutions.</li>
                <li>Hosted annual conferences bringing together leading minds in various disciplines.</li>
            </ul>
            <h3>Meet Our Team</h3>
            <div className="team-container">
                {/* Placeholder for team member profiles */}
                <div className="team-member">
                    <img src="placeholder-image-team1.jpg" alt="Team Member 1"/>
                    <h4>Dr. Jane Doe</h4>
                    <p>Founder & Research Director</p>
                </div>
                {/* Add more team members here */}
            </div>
            <p>
                At ARN, we're committed to creating a sustainable future where African researchers are at the forefront of addressing the continent's and the world's most pressing issues. Join us in this journey to unlock the potential of African research.
            </p>
        </div>
    );
}

export default AboutUs;
