import React, { useState, useEffect } from "react";
import jsonData from "./arn_members_object_format.json";
import "./MembershipPage.css";

function MembershipPage() {
  const [members, setMembers] = useState([]);
  const [expandedMember, setExpandedMember] = useState(null); // To track which member's details are expanded

  useEffect(() => {
    setMembers(jsonData);
  }, []);

  const placeholderImage = "./placeholder_image.jpg";

  const handleExpandClick = (index) => {
    if (expandedMember === index) {
      setExpandedMember(null); // Collapse if already expanded
    } else {
      setExpandedMember(index); // Expand the clicked member's details
    }
  };

  return (
    <div className="container mt-5">
      <h1 className="mb-4 text-center">ARN Members</h1>
      <div className="row">
        {members && Object.keys(members).length > 0 ? (
          Object.entries(members).map(([index, member]) => (
            <div key={index} className="col-lg-4 col-md-6 mb-4">
              <div className="member-card">
                <img
                  src={member["Photo link"] || placeholderImage}
                  alt={member["Full Name"]}
                  className="member-image"
                />
                <div className="member-details">
                  <h5 className="card-title">{member["Full Name"]}</h5>
                  <p className="card-text">{member.Email}</p>
                  <p className="card-text">{member["Membership Category"]}</p>
                  <p className="card-text">{member["Country of Residence"]}</p>

                  {expandedMember === index && (
                    <div className="expanded-details">
                      <p>
                        <strong>Academic Biography:</strong>{" "}
                        {member["Academic Biography"]}
                      </p>
                      <p>
                        <strong>Institutional Affiliation:</strong>{" "}
                        {member["Institutional affiliation"]}
                      </p>
                      <p>
                        <strong>Research Description:</strong>{" "}
                        {member["Brief description of research"]}
                      </p>
                      <p>
                        <strong>Specialization:</strong>{" "}
                        {member["Area of Specialization"]}
                      </p>
                      <p>
                        <strong>Research Interests:</strong>{" "}
                        {member["Research interests, key words"]}
                      </p>
                      <p>
                        <strong>Social Media Profile:</strong>{" "}
                        <a
                          href={member["Social media link to your profile"]}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Link
                        </a>
                      </p>
                      <p>
                        <strong>Website:</strong>{" "}
                        <a
                          href={
                            member["Website link - personal or institutional"]
                          }
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Link
                        </a>
                      </p>
                    </div>
                  )}

                  <button
                    className="expand-btn"
                    onClick={() => handleExpandClick(index)}
                  >
                    {expandedMember === index ? "Collapse" : "Expand"}
                  </button>
                </div>
              </div>
            </div>
          ))
        ) : (
          <div className="col-12">
            <p className="text-center">Loading members...</p>
          </div>
        )}
      </div>
    </div>
  );
}

export default MembershipPage;
