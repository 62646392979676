// import Management from "./Management/Management";
// import Activities from "./Activities/Activities";
// import Research from "./Research/Research";
// import Internship from "./Internship/Internship";
// import Projects from "./Projects/Projects";
// import Resources from "./Resources/Resources";
// import Donate from "./Donate/Donate";
// import Contact from "./Contact/Contact";
// import News from "./News/News";
import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom"; 
import Navigation from "../src/commons/Navigation/navigation"; // Correct path to Navigation
import HomePage from "../src/components/Home/HomePage";
import UserProfile from "./components/Membership/UserProfile"; 
import MembershipPage from "./MembershipPage";
import Webinar from "./components/Activities/Webinar/webinar";
import AboutUs from "./components/AboutUs/AboutUs";
// ... Other imports

import "./App.css";

function App() {
    return (
        <Router>
            <Navigation />

            <Routes>
                <Route path="/" element={<HomePage />} />
                <Route path="/HomePage" element={<HomePage />} />
                <Route path="/UserProfile" element={<UserProfile />} />
                <Route path="/MembershipPage" element={<MembershipPage />} />
                <Route path="Webinar" element={<Webinar />} />
                <Route path="AboutUs" element={<AboutUs />} />
                {/* Uncomment and correct other routes as needed */}
            </Routes>
        </Router>
    );
}

export default App;

//                 {/* <Route path="/UserProfile" element={<UserProfile />} />
//                 <Route path="/MembershipPage" element={<MembershipPage />} />
//                 <Route path="/Management" element={<Management />} />
//                 <Route path="/Activities" element={<Activities />} />
//                 <Route path="/Research" element={<Research />} />
//                 <Route path="/Internship" element={<Internship />} />
//                 <Route path="/Projects" element={<Projects />} />
//                 <Route path="/Resources" element={<Resources />} />
//                 <Route path="/Donate" element={<Donate />} />
//                 <Route path="/Contact" element={<Contact />} />
//                 <Route path="/News" element={<News />} /> */}
//                 {/* Add other routes as needed */}
//             </Routes>
//         </Router>
//     );
// }

// export default App;
